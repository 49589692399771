import { useRef } from "react";

import Navbar from "../Components/navBar/navBar";
import Landing from "../Components/landing/landing";
import About from "../Components/about/about";
import Servises from "../Components/servises/servises";
import Partnerships from "../Components/partnerships/partnerships";
import Attribution from "../Components/attribution/attribution";
import DownloadApp from "../Components/downloadApp/downloadApp";
import Footer from "../Components/footer/footer";

const HomePage = () => {
  const references = {
    aboutRef: useRef(null),
    servisesRef: useRef(null),
    partnershipsRef: useRef(null),
    attributionRef: useRef(null),
    downloadAppRef: useRef(null),
  };

  const scrollToComponent = (ref) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - 80,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const navMenu = [
    {
      name: "about",
      event: () => scrollToComponent(references.aboutRef),
    },
    {
      name: "servises",
      event: () => scrollToComponent(references.servisesRef),
    },
    {
      name: "partnerships",
      event: () => scrollToComponent(references.partnershipsRef),
    },
    {
      name: "attribution",
      event: () => scrollToComponent(references.attributionRef),
    },    
    {
      name: "download app",
      event: () => scrollToComponent(references.downloadAppRef),
    },
  ];

  return (
    <>
      <Navbar navMenu={navMenu} />
      <Landing event={navMenu[0].event} />
      <About reference={references.aboutRef} />
      <Servises reference={references.servisesRef} />
      <Partnerships reference={references.partnershipsRef} />
      <Attribution reference={references.attributionRef} />
      <DownloadApp reference={references.downloadAppRef} />
      <Footer />
    </>
  );
};

export default HomePage;