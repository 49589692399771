import { Routes, Route } from "react-router-dom";

import "./index.css";
import HomePage from "./Pages/homePage";
import PrivaxyPolicyPage from "./Pages/privaxyPolicyPage";
import DataDeletionInstructionPage from "./Pages/dataDeletionInstructionPage";
import UserRequestDeletionPage from "./Pages/userRequestDeletion";

function App() {
  
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/privaxy-policy" element={<PrivaxyPolicyPage />} />
      <Route
        path="/data-deletion-instruction"
        element={<DataDeletionInstructionPage />}
      />
      <Route
        path="/user-request-deletion"
        element={<UserRequestDeletionPage />}
      />
    </Routes>
  );
}

export default App;