import NavBar2 from "../Components/navBar2/navBar2";
import PrivaxyPolicy from "../Components/privaxyPolicy/privaxyPolicy";
import Footer from "../Components/footer/footer";

const PrivaxyPolicyPage = () => {
  return (
    <>
      <NavBar2 />
      <PrivaxyPolicy />
      <Footer />
    </>
  );
};

export default PrivaxyPolicyPage;