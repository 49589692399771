import { Link } from 'react-router-dom';

import './navBar2.css';
import { scrollTop } from '../../utils/scrollTop';

const NavBar2 = () => {
  return (
    <div className="nav2_page">
      <div className="ctn" >
        <div className="ab">
          <Link to='/'>
            <div className="logo" onClick={scrollTop}>
              <img src={require('../../imgs/logo.jpeg')} alt="" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavBar2;