import './bgAlerts.css';

const BgAlerts = (props) => {
  return (
    <div className={
      `bg_alerts ${ props.openClose === true ? `bg_alerts_open` : `bg_alerts_close` }`
    }>
    </div>
  );
};

export default BgAlerts;