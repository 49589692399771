import { Link } from 'react-router-dom';
import './footer.css';
import { AiOutlineMail } from 'react-icons/ai';
import { scrollTop } from '../../utils/scrollTop';

const Footer = () => {

  return (
    <div className="footer_page">
      <div className='ctn'>
        <div className='ab_header_nav'>
          <h1 className='title'>
            download 
          </h1>
          <img src={require('../../imgs/logo.jpeg')} alt="" />
        </div>
        <div className='deteals'>
          <ul>
            <li>
              <h1>
                contact
              </h1>
            </li>
            <li>
              <a href='mailto:const@dianomi.ma'>
                <AiOutlineMail className='icon' />&nbsp;dianomimaroc@gmail.com
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <h1>
               policies
              </h1>
            </li>
            <li>
              <Link to='/privaxy-policy' onClick={scrollTop}>
                privaxy policy
              </Link>
            </li>
            <li>
              <Link to='/data-deletion-instruction' onClick={scrollTop}>
                data deletion instruction
              </Link>
           </li>        
          </ul>
          <ul>
            <li>
              <h1>
                user request deletion
              </h1>
            </li>
            <li>
              <Link to='/user-request-deletion' onClick={scrollTop}>
                delete my account
              </Link>
            </li>
          </ul>          
        </div>
      </div>
    </div>
  );
};

export default Footer;