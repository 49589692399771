import NavBar2 from "../Components/navBar2/navBar2";
import UserRequestDeletion from "../Components/userRequestDeletion/userRequestDeletion";
import Footer from "../Components/footer/footer";

const UserRequestDeletionPage = () => {
  return (
    <>
      <NavBar2 />
      <UserRequestDeletion />
      <Footer />
    </>
  );
};

export default UserRequestDeletionPage;
