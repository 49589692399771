import './downloadApp.css';
import Button from '../button/button';

const DownloadApp = (props) => {
  return (
    <div className='download_app' id='download_app' ref={props.reference}>
      <div className='ctn'>
        <h1 className='title'>
          download app
        </h1>
        <div className='ab_content'>
          <div className='img'>
            <img src={require('../../imgs/3198860-removebg-preview.png')} alt='' />
          </div>
          <div className='t_des'>
            <h1>
              Dianomi
            </h1>
            <p>
              Get a unique delivery experience with our app! You don't have to worry about time or complications, we are here to bring you the joy of enjoying your favorite meal wherever you are. Make your life easier and tastier, download the application today and enjoy an unparalleled delivery experience! 🍔🚀
            </p>
            <Button content={`download app`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;