import './privaxyPolicy.css';

const data = new Date();

const PrivaxyPolicy = () => {
  return (
    <div className='privaxy_policy'>
      <div className='ctn'>
        <section>
          <h1>
            Effective Date: {data.toLocaleDateString()}
          </h1>
          <p>
            Thank you for using the Dianomi delivery application. At Dianomi, we are committed to protecting your privacy and ensuring the security of your personal information. This privacy policy outlines how we collect, use, and protect your data. By using our application, you consent to the practices described in this policy
          </p>
        </section>

        <section>
          <h1>
            Information We Collect
          </h1>
          <p>
            <strong>Personal Information:</strong> We may collect personal information such as your name, contact information, and delivery address. This data is used to create and manage your account.
          </p>
          <p>
            <strong>Delivery Information:</strong> We collect information related to your delivery requests, including delivery addresses, delivery history, and order details.
          </p>
          <p>
            <strong>Location Information:</strong> To provide our services, we may access your device's location services to determine your current location for delivery purposes. You can control location sharing through your device settings.
          </p>
        </section>

        <section>
        <h1>
            How We Use Your Information
          </h1>
          <p>
            Service Provision: We use your data to provide and improve our delivery services. This includes processing your delivery requests and optimizing your user experience.
          </p>
          <p>
            Communication: We may use your contact information to communicate with you about your account, updates to our services, and promotional offers.
          </p>
          <p>
            Analytics: We analyze user data to better understand how our application is used, to improve our services, and to provide personalized content.
          </p>
          <p>
            Legal Compliance: We may use your data to comply with legal obligations and protect our rights, property, and safety.
          </p>
        </section>

        <section>
          <h1>
            Sharing Your Information
          </h1>
          <p>
            We do not sell or rent your personal information to third parties. However, we may share your data with:
          </p>
          <p>
            Delivery Partners: We share your delivery information with our delivery partners to fulfill your orders.
          </p>
          <p>
            Legal Requirements: We may disclose your information when required by law, or when we believe it is necessary to protect our rights, privacy, safety, and property.
          </p>
        </section>

        <section>
          <h1>
            Data Security
          </h1>
          <p>
            We employ industry-standard security measures to safeguard your data from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is completely secure.
          </p>
        </section>

        <section>
          <h1>
            Your Choices
          </h1>
          <p>
            You can manage your information and communication preferences within the Dianomi application settings. You can also contact our support team to make specific data requests or to exercise your rights under applicable data protection laws.
          </p>
        </section>

        <section>
          <h1>
            Children's Privacy
          </h1>
          <p>
            Our services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13 years of age.
          </p>
        </section>

        <section>
          <h1>
            Changes to this Policy
          </h1>
          <p>
            We may update this privacy policy as our services evolve or as required by law. You will be notified of any changes through the application or other means.
          </p>
        </section>

        <section>
          <h1>
            Contact Us
          </h1>
          <p>
            If you have any questions, concerns, or requests regarding this privacy policy, please contact us at  dianomimaroc@gmail.com.
          </p>
        </section>

      </div>    
    </div>
  );
};

export default PrivaxyPolicy;