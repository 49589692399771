import "./userRequestDeletion.css";

import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Full name is required.")
    .min(2, "Full name must be at least 2 characters.")
    .max(50, "Full name must be at most 50 characters."),

  email: Yup.string()
    .required("Email is required.")
    .email("Invalid email address."),

  reasonForUnsubscribing: Yup.string()
    .optional()
    .max(200, "Reason must be at most 200 characters."),
});

const UserRequestDeletion = () => {
  const [requestSuccess, setRequestSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      reasonForUnsubscribing: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const postData = async () => {
        try {
          const req = await fetch("https://sore-jade-firefly-gear.cyclic.app/api/v1/user", {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          });
          req.status === 200
            ? setRequestSuccess(true)
            : setRequestSuccess(false);
        } catch (error) {
          console.log(error);
        }
      };
      postData();

      resetForm({
        values: {
          fullName: "",
          email: "",
          reasonForUnsubscribing: "",
        },
      });
    },
  });

  return requestSuccess === false ? (
    <div className="deletion_request_form">
      <div className="ctn">
        <h2 className="title">Deletion Request Form</h2>

        <form className="form" onSubmit={formik.handleSubmit}>
          <section>
            <label htmlFor="fullName">Full Name</label>
            <input
              type="text"
              name="fullName"
              placeholder="Full Name"
              id="fullName"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.fullName && formik.errors.fullName ? (
              <div className="error">{formik.errors.fullName}</div>
            ) : null}
          </section>

          <section>
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              placeholder="Email"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </section>

          <section>
            <label htmlFor="reasonForUnsubscribing">
              Reason For unsubscribing (optional)
            </label>
            <textarea
              name="reasonForUnsubscribing"
              placeholder="Reason For unsubscribing"
              id="reasonForUnsubscribing"
              value={formik.values.reasonForUnsubscribing}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.reasonForUnsubscribing &&
            formik.errors.reasonForUnsubscribing ? (
              <div className="error">
                {formik.errors.reasonForUnsubscribing}
              </div>
            ) : null}
          </section>

          <input type="submit" />
        </form>
      </div>
    </div>
  ) : (
    <div className="request_success">
      <div className="ctn">
        <h2 className="title">Your account has been successfully deleted.</h2>
      </div>
    </div>
  );
};

export default UserRequestDeletion;