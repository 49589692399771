import './servises.css';
import { AiOutlineCar } from 'react-icons/ai';
import { FaShopify } from 'react-icons/fa';
import { IoMdPeople } from 'react-icons/io';
import { FaClock } from 'react-icons/fa';

const Servises = (props) => {
  return (
    <div className='servises_page' id='servises' ref={props.reference}>
      <div className='ctn'>
        <h1 className='title'>
          services
        </h1>
        <div className='cards'>
          <div className='card'>
            <div className='ab_icon'>
              <AiOutlineCar />
            </div>
            <h2>
              Fast and easy delivery
            </h2>
          </div>
          <div className='card'>
            <div className='ab_icon'>
              <FaShopify />
            </div>
            <h2>
              A wide range of products and services
            </h2>
          </div>
          <div className='card'>
            <div className='ab_icon'>
              <IoMdPeople />
            </div>
            <h2>
              A team of well-trained drivers
            </h2>
          </div>
          <div className='card'>
            <div className='ab_icon'>
              <FaClock />
            </div>
            <h2>
              Ensure that the order arrives on time
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servises;