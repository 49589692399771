import './about.css';

const About = (props) => {
  return (
    <div className='about_page' id='about' ref={props.reference}>
      <div className='ctn'>
        <h1 className='title'>
          About
        </h1>
        <div className='ab_content'>
          <div className='t_des'>
            <h1>
              Dianomi
            </h1>
            <p>
              Do you want to order food from your favorite restaurant? Or get your daily needs from the supermarket? Or do you need to deliver work orders?
            </p>
            <p>
              With DIANOMI, you can order whatever you want, and it will come to you quickly and easily. We offer a wide range of products and services that can be delivered to your home or office.
            </p>
            <p>
              We have a team of well-trained drivers who will deliver your order quickly and safely. We guarantee that you will receive your order on time.
            </p>
          </div>
          <div className='img'>
            <img src={require('../../imgs/pexels-photo-4393668.jpeg')} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;