import './partnerships.css';

const partnerships = [
  {
    name: "Snack Romaisae",
    image: "https://img.freepik.com/free-photo/burrito-with-meat-vegetables-it_188544-16138.jpg?size=626&ext=jpg&ga=GA1.1.1803093810.1638625720&semt=sph"
  },
  {
    name: "Frescos",
    image: "https://img.freepik.com/free-photo/crispy-fried-chicken-wooden-cutting-board_1150-20220.jpg?size=626&ext=jpg&uid=R91074564&ga=GA1.1.1803093810.1638625720&semt=sph"
  },
  {
    name: "Tapita snack",
    image: "https://img.freepik.com/free-photo/side-view-chicken-doner-with-greens-cucumber-tomato-sauce-cucumber-red-onion-pita-slice-lemon-board_141793-4824.jpg?size=626&ext=jpg&uid=R91074564&ga=GA1.1.1803093810.1638625720&semt=sph"
  },
  {
    name: "Las vegas",
    image: "https://img.freepik.com/free-photo/burger-french-fries-plate-with-copy-space_23-2148235009.jpg?t=st=1716086367~exp=1716089967~hmac=5aecc4fb868c2788c98b947923553795da0eacb48a558a03bad6c1429481dee5&w=740"
  },
  {
    name: "Coyo Chicken",
    image: "https://img.freepik.com/free-photo/top-view-meals-tasty-yummy-different-pastries-dishes-brown-surface_140725-14554.jpg?size=626&ext=jpg&ga=GA1.1.1803093810.1638625720&semt=ais"
  },
  {
    name: "Mister Polo",
    image: "https://img.freepik.com/free-photo/crop-hand-with-tortilla-mexican-food_23-2147740724.jpg?t=st=1716421933~exp=1716425533~hmac=20a546d3ebd8475c16339d48a5356b669c2052a93de97c9bc3d952307f69dbe3&w=740"
  },
  {
    name: "Chahd Cham",
    image: "https://img.freepik.com/free-photo/beyti-kebab-served-with-ayran-pickles_141793-1868.jpg?t=st=1709042684~exp=1709046284~hmac=169b57dc409d867f4d9b0c6b0dffe20e8f6bcab71b9a50e28a78f9df677f425e&w=740"
  },
  {
    name: "Pianochi",
    image: "https://img.freepik.com/free-photo/fancy-cakes-banquet-table_1398-2226.jpg?size=626&ext=jpg&ga=GA1.1.1803093810.1638625720&semt=ais"
  },
  {
    name: "Sushi Las Vegas",
    image: "https://img.freepik.com/free-photo/painting-sushi-plate-with-picture-fish-it_188544-12221.jpg?size=626&ext=jpg&ga=GA1.1.1803093810.1638625720&semt=sph"
  },
  {
    name: "Bambo Pizza ",
    image: "https://img.freepik.com/free-photo/company-friends-eating-pizza-talking_140725-14157.jpg?t=st=1716047539~exp=1716051139~hmac=9d597ae0b91221e21af7a7c7042a8a7a4e1fb2ce575e95a633628b0e314b871e&w=740"
  },
  {
    name: "SindiBade",
    image: "https://img.freepik.com/free-photo/sandwich-tandir-bread-iwith-white-cheese-tomato-lettuce-inside_114579-3007.jpg?size=626&ext=jpg&uid=R91074564&ga=GA1.1.1803093810.1638625720&semt=sph"
  },
  {
    name: "Amsterdam Snack",
    image: "https://img.freepik.com/free-photo/happy-waiter-serving-food-group-cheerful-friends-pub_637285-12525.jpg?t=st=1715094293~exp=1715097893~hmac=093aa751e4697d71a8fc8c581336c3cca2f8b8c9ba7c8ba88588e76662a20384&w=740"
  },
  {
    name: "Cheibai Snack",
    image: "https://img.freepik.com/free-photo/sandwich-pita-bread-wooden-board_140725-1420.jpg?t=st=1716432082~exp=1716435682~hmac=718a207f344b01bf29f41c0fc60add969dd2db92a2d1075cf591a5c9639483f2&w=740"
  },
  {
    name: "Yafa Restaurant",
    image: "https://img.freepik.com/free-photo/side-view-chicken-doner-with-greens-cucumber-tomato-sauce-cucumber-red-onion-pita-slice-lemon-board_141793-4824.jpg?size=626&ext=jpg&uid=R91074564&ga=GA1.1.1803093810.1638625720&semt=sph"
  },
  {
    name: "Bosfor",
    image: "https://img.freepik.com/free-photo/kebab-varieties-with-vegetables-inside-wooden-platter_114579-3157.jpg?t=st=1716431747~exp=1716435347~hmac=094c77f09bd61b0f1fc80496ab926b67ab3f2435b09c45e0655096bfb3fd22da&w=360"
  },
  {
    name: "Café Taha",
    image: "https://img.freepik.com/free-photo/strong-coffee-with-glass-water_140725-4209.jpg?size=626&ext=jpg&ga=GA1.1.1803093810.1638625720&semt=ais"
  },
  {
    name: "Las palmas",
    image: "https://img.freepik.com/free-photo/breakfast-set-egg-with-sausages-side-view_140725-11205.jpg?size=626&ext=jpg&ga=GA1.1.1803093810.1638625720&semt=sph"
  }
];

const Partnerships = (props) => {
  return (
    <div className='partnerships_page' id='partnerships' ref={props.reference}>
      <div className='ctn'>
        <h1 className='title'>
          partnerships
        </h1>
        <div className='cards'>
          {
            partnerships.map((item, index) => {
              return (
                <div className='card' key={index}>
                  <div className='img'
                    style={{
                      backgroundImage: `url(${item.image})`
                    }}             
                  >
                  </div>
                  <h2>
                    {item.name}
                  </h2>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default Partnerships;