import './dataDeletionInstruction.css';

const DataDeletionInstruction = () => {
  return (
    <div className='data_deletion_instruction'>
      <div className='ctn'>
        <section>
          <h1>
            Delete User Account:
          </h1>
          <p>
            Open the "Dianomi" app. Log in to your account, if applicable. Navigate to the "Settings" or "Profile" section. Look for an option such as "Delete Account" , "Deactivate Account." or " log out " Follow the instructions to delete your account, which may include entering your login information or confirming the deletion. Delete Location Data:
          </p>
          <p>
            In your device settings, go to "Apps" or "Apps & Notifications." Find "Dianomi" in the list of installed apps. Select the app. Tap on "Storage." Tap on "Clear Data" or "Clear Cache" to delete location data stored by the app. Revoke Permissions:
          </p>
          <p>
            In your device settings, go to "Apps" or "Apps & Notifications." Find "Dianomi" in the list of installed apps. Select the app. Tap on "Permissions." Revoke permissions for location, storage, or other permissions you no longer wish to grant to the app. Uninstall the App:
          </p>
          <p>
            On your device's home screen, long-press the "Dianomi" app icon. Drag the icon to the "Uninstall" option that appears at the top of the screen. Confirm the uninstallation.
          </p>
          <p>
            These steps provide a general guideline for removing data associated with the "Dianomi" app on an Android device. Please note that the precise steps may vary based on the app's specific features and Android version.
          </p>
          <p>
            For specific instructions related to the "Dianomi" app, it's advisable  contact the support team , they offer assistance.
          </p>
        </section>
      </div>    
    </div>
  );
};

export default DataDeletionInstruction;