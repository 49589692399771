import "./attribution.css";

const Attribution = (props) => {
  return (
    <div className="attribution_page" id="attribution" ref={props.reference}>
      <div className="ctn">
        <h1 className="title">attribution</h1>
        <div className="t_des">
          <p>
            We would like to extend our heartfelt thanks to Freepik and Pexels for providing the amazing images that enhance our app. Their vast collection of high-quality resources has been invaluable in creating a visually appealing and engaging experience for our users.
          </p>
          <p>
            Your creativity and generosity are greatly appreciated!
          </p>
          <p style={{ marginBottom: "0" }}>
            Images provided by <a href="https://www.freepik.com/" target="_blank" rel="noreferrer" >Freepik</a> and <a href="https://www.pexels.com/" target="_blank" rel="noreferrer" >Pexels</a>.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Attribution;
