import { useState, useEffect } from "react";

import "./navBar.css";
import { AiOutlineMenu } from "react-icons/ai";
import BgAlerts from "../bgAlerts/bgAlerts";

const Navbar = ({ navMenu }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const event = () => {
    setOpenMenu(!openMenu);
  };
  useEffect(() => {
    function handleResize() {
      setOpenMenu(false);
    }
    window.addEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <nav className="nav_page">
        <div className="ctn">
          <div className="ab">
            <div className="logo">
              <img src={require("../../imgs/logo.jpeg")} alt="" />
            </div>
            <div className="icon" onClick={event}>
              <AiOutlineMenu />
            </div>
            <ul className={`menu ${openMenu === false ? `close` : `open`}`}>
              {navMenu.map((item, i) => {
                return <li onClick={item.event} key={i} >{item.name}</li>;
              })}
            </ul>
          </div>
        </div>
      </nav>
      <div onClick={event}>
        <BgAlerts openClose={openMenu} />
      </div>
    </>
  );
};

export default Navbar;
