import NavBar2 from '../Components/navBar2/navBar2';
import DataDeletionInstruction from '../Components/dataDeletionInstruction/dataDeletionInstruction';
import Footer from "../Components/footer/footer";

const DataDeletionInstructionPage = () => {
  return (
    <>
      <NavBar2 />
      <DataDeletionInstruction/>
      <Footer />
    </>
  );
};

export default DataDeletionInstructionPage;