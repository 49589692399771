import './landing.css';
import Button from '../button/button';

const Landing = (props) => {
  return ( 
    <div className='landing'>
      <div className='effect'>
        <div className='ctn'>
          <div className='ab_titles'>
            <section>
              <h1>
                Ask for what you want, and it will come to you
              </h1>
              <h2>
                Fast and easy delivery service
              </h2>
            </section>
            <div className='content'>
              <p>
                Do you want to order food from your favorite restaurant? Or get your daily needs from the supermarket? Or do you need to deliver work orders?
              </p>
            </div>
            <section>
              <Button onclick={props.event} content='Learn more' />
            </section>
          </div>
          <div className='img'>
            <img src={require('../../imgs/fn.png')} alt='' />
          </div>
        </div>
      </div>
    </div>
  )
};

export default Landing;