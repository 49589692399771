import './button.css'

const Button = (props) => {
  return ( 
    <button className='button' onClick={props.onclick}>
      {props.content}
    </button>
  )
};

export default Button;